<template>
    <Modal class="pages-selection" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.content_block_removal')}}
        </template>
        <template v-slot:body>
            <div class="buttons-group">
                <div class="button-wrap">
                    <BaseButton class="btn-warning" v-if="placement_key !== ''" @click="contentRemoved()">
                        <BaseIcon class="remove"></BaseIcon>
                        {{$t('cms.remove_block_from_page')}}
                    </BaseButton>
                    <div class="small-note">{{ $t('cms.other_pages_unchanged') }}</div>
                </div>
                <div class="button-wrap">
                    <BaseButton class="btn-danger" @click="toConfirm=true">
                        <BaseIcon class="trash lighten"></BaseIcon>
                        {{$t('cms.complete_content_delete')}}
                    </BaseButton>
                    <Confirmation 
                        :opened="toConfirm" 
                        @close="toConfirm=false" 
                        :action="'content/deleteItemByCode'" 
                        :params="{code:content.code}" 
                        @confirmed="contentDeleted()"
                    />
                    <div class="small-note">{{ $t('cms.block_removed_from_all_pages') }} {{ $t('controls.this_action_is_irreversible') }}</div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@common/components/Modal";
import { defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "RemoveContentConfirmation",
    components: {
        Modal,
        Confirmation: defineAsyncComponent(() => import(/* webpackChunkName: "confirmation" */'@common/components/base/Confirmation')),
    },
    emits: ['close'],
    props: {
        opened: Boolean,
        content: {
            type: Object,
            default: () => null
        },
        placement_key: {
            type: String,
            default: () => ""
        },
        index: {
            type: Number,
            default: () => 0
        }
    },
    setup(props,context){
        const toConfirm = ref(false);
        
        const closeForm = () => {
            context.emit('close');
        }

        const store = useStore();
        const contentDeleted = () => {
            if(props.placement_key !== ''){
                store.commit('page_editor/contentDeleted',{code: props.content.code});
            }
        }

        const contentRemoved = () => {
            if(props.placement_key !== ''){
                store.commit('page_editor/contentRemoved',{placementCode: props.placement_key,index: props.index,});
            }

        }

        return {
            toConfirm,
            closeForm,
            contentDeleted,
            contentRemoved
        }
    }
}
</script>

<style scoped>
.small-note{
    font-size: 0.75rem;
    padding: 0.5rem;
}
.buttons-group{
    display: flex;
    flex-wrap: wrap;
}
.button-wrap{
    width: 12rem;
    margin: 1rem;
    position: relative;
}
.button-wrap .btn{
    height: 10rem;
    width: 12rem;
}
.button-wrap .icon{
    width: 4rem;
    height: 4rem;
    margin: 1rem auto;
}
.button-wrap .icon.lighten{
    filter: brightness(10);
}
</style>